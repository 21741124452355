body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.projects-grid {
  display: flex;
}

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: 2em;
  padding-top: 1em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.resume-right-col {
  background: #27221F;
  color: white;
  padding-left: 1em;
}

.header-color {
  background: #1D4350;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #A43931, #1D4350);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #A43931, #1D4350); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.landing-grid {
  background: #1D4350;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #A43931, #1D4350);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #A43931, #1D4350); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
}

.nathan-img {
  height: 250px;
  padding-top: 5em;
  border-radius: 5px;
  border-color: black;
  align-content: center;
}

.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;  
  color: white;
  text-align: center;
}

.banner-text hr {
  border-top: 5px dashed white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 4em;
  padding: 8px;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 50px;
  padding-right: 5rem;
}